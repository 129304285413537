%icon {
    height: 25px;
    width: 25px;
    display: inline-block;
    position: absolute;
    left: 0;
}

.adv {
    letter-spacing: 0.05em;
    margin-bottom: 0.75rem;
    &-wrapper {
        margin-top: 1.5rem;
    }
    &.pros, &.best {
        color: get-color('success');
        position: relative;
        padding-left: 2.25rem;
        &:before {
            content: url('../../images/icons/checkmark.svg');
            padding: rem-calc(5);
            filter: brightness(0) invert(1);
            top: -2px;
            z-index: 2;
            @extend %icon;
        }
        &:after {
            content: "";
            background-color: get-color('success');
            border-radius: 50%;
            top: 0;
            z-index: 1;
            @extend %icon;
        }
    }
    &.best {
        &:before {
            content: url('../../images/icons/thumb-up.svg');
            top: -3px;
        }
    }
    &.cons {
        color: get-color('alert');
        position: relative;
        padding-left: 2.25rem;
        &:before {
            content: url('../../images/icons/cross.svg');
            padding: rem-calc(5);
            filter: brightness(0) invert(1);
            top: -2.5px;
            z-index: 2;
            @extend %icon;
        }
        &:after {
            content: "";
            background-color: get-color('alert');
            border-radius: 50%;
            top: 0;
            z-index: 1;
            @extend %icon;
        }
    }
}
