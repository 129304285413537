.list-style-dash {
  list-style-type: none;
}
.list-style-dash li {
  position: relative;
  padding-left: 1.2857142857rem;
}
.list-style-dash li::before {
  visibility: hidden;
  margin-left: -1rem;
}
.list-style-dash li::after {
  content: "-";
  font-weight: 700;
  text-indent: -5px;
  position: absolute;
  left: 0;
  top: 1px;
}

.list-style-dot {
  list-style-type: none;
}
.list-style-dot li {
  position: relative;
  padding-left: 1.2857142857rem;
}
.list-style-dot li::before {
  visibility: hidden;
  margin-left: -1rem;
}
.list-style-dot li::after {
  content: " ";
  position: absolute;
  left: -5px;
  top: 0.65rem;
  width: 6px;
  height: 6px;
  background-color: #505464;
  border-radius: 10px;
}

.adv.cons:after, .adv.cons:before, .adv.pros:after, .adv.best:after, .adv.pros:before, .adv.best:before {
  height: 25px;
  width: 25px;
  display: inline-block;
  position: absolute;
  left: 0;
}

.adv {
  letter-spacing: 0.05em;
  margin-bottom: 0.75rem;
}
.adv-wrapper {
  margin-top: 1.5rem;
}
.adv.pros, .adv.best {
  color: #27AE60;
  position: relative;
  padding-left: 2.25rem;
}
.adv.pros:before, .adv.best:before {
  content: url("../../images/icons/checkmark.svg");
  padding: 0.3571428571rem;
  filter: brightness(0) invert(1);
  top: -2px;
  z-index: 2;
}
.adv.pros:after, .adv.best:after {
  content: "";
  background-color: #27AE60;
  border-radius: 50%;
  top: 0;
  z-index: 1;
}
.adv.best:before {
  content: url("../../images/icons/thumb-up.svg");
  top: -3px;
}
.adv.cons {
  color: #EC2748;
  position: relative;
  padding-left: 2.25rem;
}
.adv.cons:before {
  content: url("../../images/icons/cross.svg");
  padding: 0.3571428571rem;
  filter: brightness(0) invert(1);
  top: -2.5px;
  z-index: 2;
}
.adv.cons:after {
  content: "";
  background-color: #EC2748;
  border-radius: 50%;
  top: 0;
  z-index: 1;
}

.callout.success {
  padding-left: 3rem;
  background-color: #f7fffb;
  border-color: #27AE60;
  color: #3D3B3B;
}
.callout.success:before {
  content: "\e9a2";
  color: #27AE60;
  font-size: 1.1428571429rem;
}
.callout.success.closeable {
  padding-right: 2.5rem;
}

.callout.error {
  padding-left: 3rem;
  background-color: #fff2f4;
  border-color: #EC2748;
  color: #3D3B3B;
}
.callout.error:before {
  content: "\e91d";
  color: #EC2748;
  font-size: 1.1428571429rem;
}
.callout.error.closeable {
  padding-right: 2.5rem;
}

.callout.alert {
  padding-left: 3rem;
  background-color: #fff2f4;
  border-color: #EC2748;
  color: #3D3B3B;
}
.callout.alert:before {
  content: "\e91d";
  color: #EC2748;
  font-size: 1.1428571429rem;
}
.callout.alert.closeable {
  padding-right: 2.5rem;
}

.cms-page-view .page-main p {
  margin-bottom: 2rem;
}
.cms-page-view .page-main ul li, .cms-page-view .page-main ol li {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.cms-page-view .page-main ul.compact, .cms-page-view .page-main ol.compact {
  margin-left: 1rem;
}
.cms-page-view .page-main ul.compact li, .cms-page-view .page-main ol.compact li {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.cms-page-view .page-title {
  margin-bottom: 2rem;
}

.cms-section {
  margin: 4rem auto;
}

.blue-txt {
  color: #1E7EB0;
}

.content-heading {
  font-size: 1.7142857143rem;
  margin: 1rem 0;
}

.section-title {
  font-family: "factoria", "open-sans", Georgia, serif;
  border-bottom: 2px solid #3D3B3B;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
}

.list-spacing {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.content-sub-heading {
  font-size: 1.4285714286rem;
  margin: 2rem 0 0.5rem;
}

.list-heading {
  font-size: 1.4285714286rem;
  font-weight: 700;
  color: #1E7EB0;
  margin: 0 0 1rem -0.25rem;
}

.img-panel {
  border-radius: 0;
  border: 1px solid #cccccc;
  padding: 1rem 2rem;
  margin: 1rem 0.5rem;
  text-align: center;
}
.img-panel.column, .img-panel.columns {
  min-width: calc(50% - 1rem);
}
.img-panel img {
  filter: contrast(20%);
  max-height: 35px;
  width: auto;
}
.img-panel:hover {
  border-bottom-width: 1px;
  border-color: transparent;
  background: #1E85BB;
}
.img-panel:hover img {
  filter: brightness(0) invert(1);
}

.aside-wrapper {
  background-color: #f7f7f7;
  padding: 2rem;
  margin-bottom: 2rem;
}
.aside-wrapper .content-sub-heading {
  margin-top: 0.5rem;
}

.tb-card {
  background-color: #f7f7f7;
  padding: 1rem;
  margin-bottom: 2rem;
}
.tb-card .tb-title {
  font-size: 1.4285714286rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.cat-title {
  color: #1E7EB0;
  font-family: "factoria", "open-sans", Georgia, serif;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.cms-table-col {
  width: 25%;
}
.cms-table .ht-col {
  background: rgba(35, 130, 71, 0.25);
  font-weight: 700;
}
.cms-table tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}
.cms-table tbody tr:nth-child(odd) {
  background-color: transparent;
}
.cms-table.ship tbody tr td:first-child {
  font-weight: 600;
}

.cms-page-view .help-card {
  border: 1px solid #cccccc;
  padding: 2rem;
  text-align: center;
  margin-left: 2.5rem;
}
.cms-page-view .help-card-title {
  font-size: 1.7142857143rem;
  margin: 0 0 1rem;
}
.cms-page-view .help-card-item {
  margin: 2rem 0;
}
.cms-page-view .help-card-item .title {
  display: block;
  max-width: 200px;
  font-size: 1.2857142857rem;
  font-weight: 700;
  border-bottom: 1px solid #3D3B3B;
  margin: 0 auto 0.5rem;
}
.cms-page-view .help-card-item .link {
  font-weight: 700;
}
.cms-page-view .help-card-item:last-of-type {
  margin-bottom: 0;
}
.cms-page-view .callout p {
  margin-bottom: 0.25rem;
}

.captcha-block {
  margin: 2rem 0 3rem;
}

@media screen and (min-width: 48em) {
  .cms-table-col {
    width: 20%;
  }
  .tb-card {
    padding: 2rem;
  }
  .img-panel {
    padding: 2rem;
    margin: 1rem;
  }
  .img-panel.column, .img-panel.columns {
    min-width: 0;
  }
  .cms-page-view .help-card-wrapper {
    display: flex;
  }
  .cms-page-view .help-card-wrapper .help-card-item {
    width: 33.33%;
    margin: 1rem 0;
  }
}
.main-content a {
  word-break: break-all;
}

.table-overflow {
  overflow-y: auto;
}

.cms-faq.accordion .accordion-title {
  font-size: 1.1428571429rem;
  line-height: 1.5;
  word-break: break-word;
}
.cms-faq.accordion .accordion-title:before {
  top: 0;
  margin-top: 0.25rem;
}

@media screen and (min-width: 64em) {
  .main-content a {
    word-break: initial;
  }
  .table-overflow {
    overflow-y: hidden;
  }
  .aside-wrapper {
    margin: 0 0 0 2.5rem;
  }
  .cms-page-view .help-card-wrapper {
    flex-direction: column;
  }
  .cms-page-view .help-card-wrapper .help-card-item {
    width: 100%;
  }
}