.list-style-dash {
    list-style-type: none;
    li {
        position: relative;
        padding-left: rem-calc(18);
        &::before {
            visibility: hidden;
            margin-left: -1rem;
        }
        &::after {
            content: "-";
            font-weight: 700;
            text-indent: -5px;
            position: absolute;
            left: 0;
            top: 1px;
        }
    }
}
.list-style-dot {
    list-style-type: none;
    li {
        position: relative;
        padding-left: rem-calc(18);
        &::before {
            visibility: hidden;
            margin-left: -1rem;
        }
        &::after {
            content: " ";
            position: absolute;
            left: -5px;
            top: 0.65rem;
            width: 6px;
            height: 6px;
            background-color: #505464;
            border-radius: 10px;
        }
    }
}
