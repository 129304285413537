//Valid value: alert, warning, info, success with no quote
@import '../../../styles/vendor/icomoon/variables/variables';

@mixin callout($type){
    $bg-color: $cas-note-overlay;
    $color: get-color(primary);
    $icon: $cas-i-notification;
    @if ($type == 'alert' or $type == 'error'){
        $bg-color: $cas-alert-overlay;
        $color: get-color(alert);
        $icon: $cas-i-error;
    } @else if($type == 'warning'){
        $bg-color: $cas-warning-overlay;
        $color: get-color(warning);
        $icon: $cas-i-warning;
    } @else if($type == 'info' or $type == 'note'){
        $bg-color: $cas-info-overlay;
        $color: get-color(info);
        $icon: $cas-i-success;
    } @else if($type == 'success'){
        $bg-color: $cas-success-overlay;
        $color: get-color(success);
        $icon: $cas-i-success;
    } @else if($type == 'coupon-notice'){
        $bg-color: $cas-success-overlay;
        $color: get-color(success);
        $icon: url('#{$svg-icons-path}coupon.svg');
    }
    .callout.#{$type}{
        padding-left: 3rem;
        background-color: $bg-color;
        border-color: $color;
        color: $body-font-color;
        &:before{
            content: $icon;
            color: $color;
            font-size: rem-calc(16);
        }
        &.closeable{
            padding-right: 2.5rem;
        }
    }
}
