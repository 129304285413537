@import '../../../styles/settings';
@import '../../../styles/ci/mixins';
@import 'block/list-style';
@import 'block/pro-cons';

$svg-icons-path: '../../images/icons/';

@include callout(success);
@include callout(error);
@include callout(alert);

.cms-page-view {
    .page-main {
        p {
            margin-bottom: 2rem;
        }
        ul, ol {
            li {
                margin-top: 0.5rem;
                margin-bottom: 1rem;
            }
            &.compact {
                margin-left: 1rem;
                li {
                    margin-top: 0.25rem;
                    margin-bottom: 0.25rem;
                }
            }
        }
    }
    .page-title {
        margin-bottom: 2rem;
    }
}

.cms-section {
    margin: 4rem auto;
}

.blue-txt {
    color: get-color('blue');
}

.content-heading {
    font-size: rem-calc(24);
    margin: 1rem 0;
}
.section-title {
    font-family: $sec-font;
    border-bottom: 2px solid $dark-gray;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
}


.list-spacing {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.content-sub-heading {
    font-size: rem-calc(20);
    margin: 2rem 0 .5rem;
}

.list-heading {
    font-size: rem-calc(20);
    font-weight: 700;
    color: get-color('blue');
    margin: 0 0 1rem -0.25rem;
}

.img-panel {
    &.column, &.columns{
        min-width: calc(50% - 1rem);
    }
    border-radius: $global-radius;
    border: $global-border;
    padding: 1rem 2rem;
    margin: 1rem 0.5rem;
    text-align: center;

    img {
        filter: contrast(20%);
        max-height: 35px;
        width: auto;
    }

    &:hover {
        border-bottom-width: 1px;
        border-color: transparent;
        background: get-color('cyan');

        img {
            filter: brightness(0) invert(1);
        }
    }
}

.aside-wrapper {
    background-color: $off-white;
    padding: 2rem;
    margin-bottom: 2rem;
    .content-sub-heading {
        margin-top: 0.5rem;
    }
}

.tb-card {
    background-color: $off-white;
    padding: 1rem;
    margin-bottom: 2rem;
    .tb-title {
        font-size: rem-calc(20);
        margin-top: 1rem;
        margin-bottom: 2rem;

    }
}

.cat-title {
    color: get-color('primary');
    font-family: $work-sans;
    font-size: rem-calc(28);
    margin-bottom: 1.5rem;
}

.cms-table {
    &-col {
        width: 25%;
    }
    .ht-col {
        background: rgba(get-color('green'), 0.25);
        font-weight: 700;
    }
    tbody tr {
        &:nth-child(even) {
            background-color: $off-white;
        }
        &:nth-child(odd) {
            background-color: transparent;
        }
    }
    &.ship {
        tbody tr {
            td:first-child {
                font-weight: 600;
            }
        }
    }
}

.cms-page-view {
    @import 'block/help-card';
    .callout {
        p {
            margin-bottom: 0.25rem;
        }
    }
}

.captcha-block {
    margin: 2rem 0 3rem;
}

@media screen and #{breakpoint(medium)} {
    .cms-table {
        &-col {
            width: 20%;
        }
    }
    .tb-card {
        padding: 2rem;
    }

    .img-panel {
        &.column, &.columns {
            min-width: 0;
        }
        padding: 2rem;
        margin: 1rem;
    }
    .cms-page-view {
        .help-card-wrapper {
            display: flex;
            .help-card-item {
                width: 33.33%;
                margin: 1rem 0;
            }
        }
    }
}
.main-content a {
    word-break: break-all;
}
.table-overflow {
    overflow-y: auto;
}

.cms-faq.accordion {
    .accordion-title {
        font-size: rem-calc(16);
        line-height: 1.5;
        word-break: break-word;
        &:before {
            top: 0;
            margin-top: 0.25rem;
        }
    }
}

@media screen and #{breakpoint(large)} {
    .main-content a {
        word-break: initial;
    }
    .table-overflow {
        overflow-y: hidden;
    }
    .aside-wrapper {
        margin: 0 0 0 2.5rem;
    }
    .cms-page-view {
        .help-card-wrapper {
            flex-direction: column;
            .help-card-item {
                width: 100%;
            }
        }
    }
}

