.help-card {
    border: $global-border;
    padding: 2rem;
    text-align: center;
    margin-left: 2.5rem;
    &-title {
        font-size: rem-calc(24);
        margin: 0 0 1rem;
    }
    &-item {
        margin: 2rem 0;
        .title {
            display: block;
            max-width: 200px;
            font-size: rem-calc(18);
            font-weight: 700;
            border-bottom: 1px solid $dark-gray;
            margin: 0 auto 0.5rem;
        }
        .link {
            font-weight: 700;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}
