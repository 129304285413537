$cas-i-order-history: "\e97b";
$cas-i-plus-circle: "\e97c";
$cas-i-asc-arrow: "\e930";
$cas-i-condition: "\e973";
$cas-i-desc-arrow: "\e975";
$cas-i-in-cart: "\e979";
$cas-i-order-review: "\e98d";
$cas-i-qa: "\e99b";
$cas-i-reorder: "\e99e";
$cas-i-return: "\e99f";
$cas-i-small-grid: "\e9a0";
$cas-i-star-rating: "\e9a1";
$cas-i-success: "\e9a2";
$cas-i-menu: "\e92f";
$cas-i-yelp: "\e960";
$cas-i-lquo: "\ea56";
$cas-i-amazon-pay: "\e900";
$cas-i-address-book: "\e901";
$cas-i-alarm2: "\e902";
$cas-i-amex: "\e903";
$cas-i-arrow-down: "\e904";
$cas-i-arrow-down2: "\e905";
$cas-i-arrow-left: "\e906";
$cas-i-arrow-left2: "\e907";
$cas-i-arrow-right: "\e908";
$cas-i-arrow-right2: "\e909";
$cas-i-arrow-up: "\e90a";
$cas-i-arrow-up2: "\e90b";
$cas-i-attachment: "\e90c";
$cas-i-bell: "\e90d";
$cas-i-finder: "\e90e";
$cas-i-blocked: "\e90f";
$cas-i-knowledge-base: "\e910";
$cas-i-bookmark: "\e911";
$cas-i-books: "\e912";
$cas-i-briefcase: "\e913";
$cas-i-bubble: "\e914";
$cas-i-bubble2: "\e915";
$cas-i-bubbles: "\e916";
$cas-i-chat: "\e917";
$cas-i-bubbles3: "\e918";
$cas-i-bubbles4: "\e919";
$cas-i-busy: "\e91a";
$cas-i-camera: "\e91b";
$cas-i-video: "\e91c";
$cas-i-error: "\e91d";
$cas-i-cart: "\e91e";
$cas-i-checkmark: "\e91f";
$cas-i-checkmark2: "\e920";
$cas-i-clock: "\e921";
$cas-i-cross: "\e922";
$cas-i-gear: "\e923";
$cas-i-coin: "\e924";
$cas-i-logo-icon: "\e925";
$cas-i-copy: "\e926";
$cas-i-clipboard: "\e927";
$cas-i-card: "\e928";
$cas-i-discover: "\e929";
$cas-i-save: "\e92a";
$cas-i-download: "\e92b";
$cas-i-download2: "\e92c";
$cas-i-download3: "\e92d";
$cas-i-drawer: "\e92e";
$cas-i-drawer2: "\e931";
$cas-i-drawer3: "\e932";
$cas-i-droplet: "\e933";
$cas-i-enter: "\e934";
$cas-i-email: "\e935";
$cas-i-exit: "\e936";
$cas-i-eye: "\e937";
$cas-i-eye2: "\e938";
$cas-i-eye-blocked: "\e939";
$cas-i-facebook: "\e93a";
$cas-i-file: "\e93b";
$cas-i-file2: "\e93c";
$cas-i-file3: "\e93d";
$cas-i-file4: "\e93e";
$cas-i-file-pdf: "\e93f";
$cas-i-film: "\e940";
$cas-i-filter: "\e941";
$cas-i-flag: "\e942";
$cas-i-folder: "\e943";
$cas-i-folder-open: "\e944";
$cas-i-forward: "\e945";
$cas-i-gift-box: "\e946";
$cas-i-google: "\e947";
$cas-i-google-plus: "\e948";
$cas-i-large-grid: "\e949";
$cas-i-heart: "\e94a";
$cas-i-heart2: "\e94b";
$cas-i-history: "\e94c";
$cas-i-home: "\e94d";
$cas-i-address: "\e94e";
$cas-i-image2: "\e94f";
$cas-i-info: "\e950";
$cas-i-key2: "\e951";
$cas-i-eco-leaf: "\e952";
$cas-i-chain: "\e953";
$cas-i-list-view: "\e954";
$cas-i-pin: "\e955";
$cas-i-lock: "\e957";
$cas-i-mastercard: "\e958";
$cas-i-meter: "\e959";
$cas-i-moneyback: "\e95a";
$cas-i-newspaper: "\e95b";
$cas-i-external: "\e95c";
$cas-i-notax: "\e95d";
$cas-i-knowledge: "\e95e";
$cas-i-notification: "\e95f";
$cas-i-corporate: "\e961";
$cas-i-order-tracking: "\e962";
$cas-i-paste: "\e963";
$cas-i-paste2: "\e964";
$cas-i-paste3: "\e965";
$cas-i-paypal: "\e966";
$cas-i-paypal2: "\e967";
$cas-i-paypal3: "\e968";
$cas-i-pencil: "\e969";
$cas-i-phone: "\e96a";
$cas-i-piggy-bank: "\e96b";
$cas-i-pinterest: "\e96c";
$cas-i-play: "\e96e";
$cas-i-plus: "\e96f";
$cas-i-printer: "\e970";
$cas-i-profile: "\e971";
$cas-i-help: "\e972";
$cas-i-recycle: "\e974";
$cas-i-trashcan: "\e976";
$cas-i-checklist: "\e977";
$cas-i-spam: "\e978";
$cas-i-spinner: "\e97a";
$cas-i-stack: "\e97d";
$cas-i-star: "\e97e";
$cas-i-half-star: "\e97f";
$cas-i-full-star: "\e980";
$cas-i-stopwatch: "\e981";
$cas-i-support: "\e982";
$cas-i-switch: "\e983";
$cas-i-table: "\e984";
$cas-i-coupon: "\e987";
$cas-i-guarantee: "\e988";
$cas-i-thumbs-down: "\e989";
$cas-i-trophy: "\e98a";
$cas-i-truck: "\e98b";
$cas-i-twitter: "\e98c";
$cas-i-unlocked: "\e98e";
$cas-i-upload: "\e98f";
$cas-i-account: "\e992";
$cas-i-account2: "\e993";
$cas-i-visa: "\e994";
$cas-i-wand: "\e995";
$cas-i-warning: "\e996";
$cas-i-wordpress: "\e997";
$cas-i-troubleshoot: "\e999";
$cas-i-youtube: "\e99a";
$cas-i-ticket: "\e956";
$cas-i-store-credit: "\e96d";
$cas-i-rquo: "\e99c";
$cas-i-search: "\e99d";
$cas-i-warranty: "\e9b4";

